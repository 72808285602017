import { DaakiaMeeting } from '@daakia/react-sdk';
import DaakiaVC from "daakia-vc-sdk";
import React, {useEffect, useState, useRef} from "react";
import CommonService from "../../apis/CommonService";
import {
    CHECK_HOST_JOINED_MEETING,
    HOST_JOINING, HOST_MEETING_LEAVE, MEETING_CONFIG,
    VALIDATE_MEETING,
    VALIDATE_MEETING_HOST,
    WEBHOOK_HOST_MEETING_JOINING
} from "../../apis/ApiConstants";
import {useParams, redirect} from "react-router-dom";
import {Alert, message, Spin} from "antd";
import Title from "antd/es/skeleton/Title";
import Lobby from "./lobby"
import HostEmailValidationModal from "./hostEmailValidationModal";
import {AUTH_TOKEN} from "../../constants";
import {encoder} from "../../utils";



const HostMeetingInvite = () => {
    const params = useParams();
    const apiRef = useRef();
    const [meetingExist, setMeetingExist] = useState();
    const [loading, setLoading] = useState(false)
    const [countdown, setCountdown] = useState(10);
    const [redirect, setRedirect] = useState(false);
    const [inLobby, setInLobby] = useState(false);
    const [showEmailValidationModal, setShowEmailValidationModal] = useState(false);
    const [isMeetingExist, setIsMeetingExist] = useState();
    const [meetingTime, setMeetingTime] = useState('');
    const [meetingData, setMeetingData] = useState(null);
    const [hostToken, setHostToken] = useState(null);
    const [redirectionUrl, setRedirectionUrl] = useState(null);
    const [hostVerified, setHostVerified] = useState(false);
    const [meetingStyle, setMeetingStyle] = useState();


    useEffect(()=> {
        fetchMeetingExist()
        getMeetingConfiguration()
    },[])


    useEffect(() => {
        if(isMeetingExist === false) {
            const countdownInterval = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);

            setTimeout(() => {
                window.location.href = "https://www.daakia.co.in/"
                setRedirect(true);
                clearInterval(countdownInterval);
            }, 10000);

            return () => {
                clearInterval(countdownInterval);
            };
        }
    }, [isMeetingExist]);

    // useEffect(() => {
    //     const handleUserRoles = (externalApi) => {
    //         // Assuming you have access to the external API object
    //         externalApi.addEventListener('participantJoined', (participant) => {
    //             const role = participant.role; // Access the role here
    //             console.log("role: ", role)
    //         });
    //     };
    //
    //     handleUserRoles();
    // },[])

    const fetchMeetingExist = async () => {
        setLoading(true)
        const response = await CommonService.get(VALIDATE_MEETING, {
            uid: params.meeting_id,
        });
        if(response.success === 0) {
            setIsMeetingExist(false)
            // window.location.href = "https://www.daakia.co.in/"
        }else {
            setShowEmailValidationModal(true)
            setMeetingData(response?.data)
            // setInLobby(response?.data?.can_start_meeting)
            setInLobby(!response?.data?.can_start_meeting)
            setMeetingTime(response?.data?.meeting_time)

            //To set local storage auth key to validate user
            localStorage.setItem(AUTH_TOKEN, response?.data?.saas_id)


        }

        setMeetingExist(response.data)
        setLoading(false)
    }

    const getMeetingConfiguration = async () => {
        setLoading(true)
        const response = await CommonService.get(MEETING_CONFIG, {
            meeting_uid: params.meeting_id,
        });
        setRedirectionUrl(response?.data?.meeting_end_redirect_url)
        setLoading(false)
    }

    // const onMeetingClose = () => {
    //     // handleMeetingLeave();
    //     localStorage.removeItem(AUTH_TOKEN)
    //     window.location.href = "https://www.daakia.co.in/"
    // }

    /*const iframeStyle = `
    /!* Your CSS styles go here *!/
    body {
      background-color: #f0f0f0;
      height: 100%;
      display: none;
    }
  `;*/



    const handelDaakiaVcCloseCallBack = (vcCallBack) => {
        if(vcCallBack === 'room_ended') {
            localStorage.removeItem(AUTH_TOKEN)
            window.location.href = redirectionUrl ? redirectionUrl :"https://www.daakia.co.in/"
        }

        if(vcCallBack === 'participant_removed') {
            localStorage.removeItem(AUTH_TOKEN)
            window.location.href = redirectionUrl ? redirectionUrl :"https://www.daakia.co.in/"
        }

        if(vcCallBack === 'client_initiated') {
            localStorage.removeItem(AUTH_TOKEN)
            window.location.href = redirectionUrl ? redirectionUrl :"https://www.daakia.co.in/"
        }

    }

    /*const setupMeetingIFrameRef = iframeRef => {
        iframeRef.style.marginTop = '10px';
        iframeRef.style.border = '2px dashed #111111';
        iframeRef.style.padding = '2px';
        iframeRef.style.height = '600px';
    };*/

/*
    const handleDaakMeetApiReady = async apiObj => {
        // console.log("apiObj: ", apiObj)

        apiRef.current = apiObj;
        //To find meeting div and add style for full screen
        let outerHtml = apiRef.current._parentNode.outerHTML
        const idMatch = outerHtml.match(/id="([^"]*)"/)[1];
        let meetingStyle = document.getElementById(idMatch)
        meetingStyle.style.height = '100vh'

        apiObj.addEventListener('participantRoleChanged', async function (event) {
            // console.log("event: ", event)
            // await hostJoined();
            if(event.role === "moderator") {
                await webhookMeetingHostJoined();
            }

            if(event.role === 'moderator') {
                // apiObj.executeCommand('revokeModerator', event.id);

                apiObj.executeCommand('toggleLobby', event.id);
            }
        });
        apiObj.addEventListener('participantJoined', async (participant) => {
            // const role = participant.role; // Access the role here
            // console.log("role: ", role)
            // await hostJoined();
        });

    };
*/

    const onFinishEmailValidation = async (values) => {
        const response = await CommonService.create(VALIDATE_MEETING_HOST,
            {
                email: values.email,
                pin: values.pin,
                meeting_id: params.meeting_id,
            })
        console.log("response: ",response)
        if(response.success === 1) {
            message.success(`Host Verification Successful!`);
            setHostVerified(true)
            setShowEmailValidationModal(false)
            setHostToken(response?.data?.token);
        }else {
            message.error(response.message);
        }
    }

    // const hostJoined = async () => {
    //     const response = await CommonService.create(HOST_JOINING,
    //         {
    //             meeting_id: params.meeting_id,
    //         })
    //     if(response.success === 1) {
    //
    //     }else {
    //         message.error(response.message);
    //     }
    // }

    // const webhookMeetingHostJoined = async () => {
    //     const response = await CommonService.create(WEBHOOK_HOST_MEETING_JOINING,
    //         {
    //             room_uid: params.meeting_id,
    //         })
    //     if(response.success === 1) {
    //
    //     }else {
    //         message.error(response.message);
    //     }
    // }

    // const handleMeetingLeave = async () => {
    //     // if(hostVerified === true) {
    //         const response = await CommonService.updateData(HOST_MEETING_LEAVE,
    //             {
    //                 meeting_id: params.meeting_id,
    //             })
    //         if (response.success === 1) {
    //         } else {
    //             message.error(response.message);
    //         }
    //     // }
    // }

    return(
        <div style={{height: "100%", width: "100%"}}>
            {(showEmailValidationModal === false ?
            <Spin tip="Loading..." spinning={loading}>
                {(isMeetingExist === false && inLobby === false ? <>
                    <div style={{display: "block", marginTop: "10%"}}>
                        <h4 style={{textAlign: "center", fontWeight: 600, top: '50%'}}>
                            The meeting you are trying <br/> to join does not exist or expired. Please contact the host
                        </h4>
                        <p style={{textAlign: 'center'}}>You Will Be Redirected In {countdown} Seconds</p>
                    </div>
                </> : inLobby ? <Lobby meeting_time={meetingTime} />  :<>
                    <DaakiaVC
                        id={encoder(params.meeting_id)}
                        licenseKey={meetingData?.saas_id}
                        websiteBaseUrl={window.location.origin}
                        apiToken={hostToken}
                        onClose={handelDaakiaVcCloseCallBack}
                        inviteUrl={`${process.env.REACT_APP_SHARE_MEETING_URL_ENDPOINT}${params.meeting_id}`}
                    />
                    {/*<DaakiaMeeting
                        domain={process.env.REACT_APP_CONFRENCE_HOST}
                        roomName={params.meeting_id}
                        // spinner = { renderSpinner }
                        configOverwrite={{
                            logging: {
                                defaultLogLevel: 'error',
                                disableLogCollector: true
                            },
                            transcription: {
                                enabled: true,
                                autoTranscribeOnRecord: false
                            },
                            readOnlyName: true,
                            subject: meetingData?.meeting_title,
                            // notifications:[
                            //     'notify.participantWantsToJoin'
                            // ],
                            toolbarButtons: [
                                'microphone',
                                'camera',
                                'fullscreen',
                                'fodeviceselection',
                                'hangup',
                                'chat',
                                'settings',
                                'tileview',
                                'desktop',
                                'raisehand',
                                'download',
                                'help',
                                'profile',
                                'stats',
                                'noisesuppression',
                                'participants-pane',
                                'recording',
                                'shareaudio',
                                'sharedvideo',
                                'whiteboard',
                                'select-background'
                            ],
                        }}
                        userInfo={{
                            displayName: meetingData?.host_name || 'Friendly Name',
                            email: meetingData?.host_email || 'Your Email'
                        }}

                        // getIFrameRef = { setupMeetingIFrameRef }
                        onReadyToClose = { () => onMeetingClose() }
                        onApiReady = { externalApi => handleDaakMeetApiReady(externalApi) }

                    >
                        <iframe
                            title="Daakia Meeting"
                            srcDoc={`<!DOCTYPE html><html><head><style>${iframeStyle}</style></head><body></body></html>`}

                        />
                    </DaakiaMeeting>*/}
                </>)}
            </Spin>
                :<></>)}
            <HostEmailValidationModal
                visible={showEmailValidationModal}
                close={() => setShowEmailValidationModal(false)}
                onFinish={(values) => onFinishEmailValidation(values)}
            />
        </div>
    )
}


export default HostMeetingInvite;
