import { DaakiaMeeting } from '@daakia/react-sdk';
import DaakiaVC from "daakia-vc-sdk";

import React, {useEffect, useState, useRef, Fragment, useCallback} from "react";
import CommonService from "../../apis/CommonService";
import {
    CHECK_HOST_JOINED_MEETING,
    MEETING_CONFIG,
    VALIDATE_MEETING,
    VALIDATE_MEETING_HOST
} from "../../apis/ApiConstants";
import {useParams, redirect} from "react-router-dom";
import {Alert, message, Spin} from "antd";
import Title from "antd/es/skeleton/Title";
import Lobby from "./lobby"
import {AUTH_TOKEN} from "../../constants";
import ConfrenceTranscription from "../Common/ConfrenceTranscription";
import moment from "moment";
import {baseUrlGenerator, encoder} from "../../utils";



const MeetingInvite = () => {
    const params = useParams();
    const apiRef = useRef();
    const [meetingExist, setMeetingExist] = useState();
    const [loading, setLoading] = useState(false)
    const [countdown, setCountdown] = useState(10);
    const [redirect, setRedirect] = useState(false);
    const [inLobby, setInLobby] = useState(false);
    const [hostJoined, setHostJoined] = useState(false);
    const [isMeetingExist, setIsMeetingExist] = useState();
    const [meetingTime, setMeetingTime] = useState('');
    const [meetingData, setMeetingData] = useState(null);
    const [hostToken, setHostToken] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    const [redirectionUrl, setRedirectionUrl] = useState(null);
    const [transcriberId, setTranscriberId] = useState(null);
    const [transcriberVisible, setTranscriberVisible] = useState(false);
    let transId = null;
    const [daakiaTranscription, setDaakiaTranscription] = useState([
        /*{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "one1314 6 jhgf gfj htr 11 h y th 541fg t thy tg51 h thy tr 2tgh 1th th51hyt1h",
            "time": "16:04"
        },
        {
            "participantName": "Govind Jangir",
            "transcriptionChunk": "one",
            "time": "16:04"
        },
        {
            "participantName": "Govind Jangir",
            "transcriptionChunk": "to three",
            "time": "16:04"
        },
        {
            "participantName": "Govind Jangir",
            "transcriptionChunk": "to three",
            "time": "16:04"
        },
        {
            "participantName": "Govind Jangir",
            "transcriptionChunk": "so",
            "time": "16:04"
        },
        {
            "participantName": "Govind Jangir",
            "transcriptionChunk": "so",
            "time": "16:04"
        },
        {
            "participantName": "Govind Jangir",
            "transcriptionChunk": "for",
            "time": "16:04"
        },
        {
            "participantName": "Govind Jangir",
            "transcriptionChunk": "for",
            "time": "16:04"
        },
        {
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },{
            "participantName": "Govind Jangir",
            "transcriptionChunk": "6",
            "time": "16:04"
        },*/
    ]);

    useEffect(()=> {
        fetchMeetingExist()
        validateHostMeetingJoined()
        getMeetingConfiguration()
    },[])



    useEffect(() => {
        if(isMeetingExist === false) {
            const countdownInterval = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);

            setTimeout(() => {
                window.location.href = redirectionUrl ? redirectionUrl :"https://www.daakia.co.in/"
                setRedirect(true);
                clearInterval(countdownInterval);
            }, 10000);

            return () => {
                clearInterval(countdownInterval);
            };
        }
    }, [isMeetingExist]);

    const fetchMeetingExist = async () => {

        setLoading(true)
        const response = await CommonService.get(VALIDATE_MEETING, {
            uid: params.meeting_id,
        });
        if(response.success === 0) {
            setIsMeetingExist(false)
            // window.location.href = "https://www.daakia.co.in/"
        }else {
            setMeetingData(response?.data)
            localStorage.setItem(AUTH_TOKEN, response?.data?.saas_id)
            setApiKey(response?.data?.saas_id)
            setInLobby(!response?.data?.inviti_can_start_meeting)
            setMeetingTime(response?.data?.meeting_time)
            //To set local storage auth key to validate user
            validateHostMeetingJoined()

        }

        setMeetingExist(response.data)
        setLoading(false)
    }

    const onMeetingClose = () => {
        localStorage.removeItem(AUTH_TOKEN)
        window.location.href = "https://www.daakia.co.in/"
    }

    const validateHostMeetingJoined = async () => {
        const response = await CommonService.get(CHECK_HOST_JOINED_MEETING,
            {
                meeting_id: params.meeting_id,
            })
        if(response.success === 1) {
            setHostJoined(response?.data?.host_joined)
        }else {
            message.error(response.message);
        }
    }

    const iframeStyle = `
    /* Your CSS styles go here */
    body {
      background-color: #f0f0f0;
      height: 100%;
      display: none;
    }
  `;


    // const setupMeetingIFrameRef = iframeRef => {
    //     iframeRef.style.height = '100vh';
    //     iframeRef.style.width = '100vw';
    //     // iframeRef.style.display = 'none';
    // };

    const handelDaakiaVcCloseCallBack = (vcCallBack) => {
        if(vcCallBack === 'room_ended') {
            localStorage.removeItem(AUTH_TOKEN)
            window.location.href = redirectionUrl ? redirectionUrl :"https://www.daakia.co.in/"

        }

        if(vcCallBack === 'participant_removed') {
            localStorage.removeItem(AUTH_TOKEN)
            window.location.href = redirectionUrl ? redirectionUrl :"https://www.daakia.co.in/"

        }

        if(vcCallBack === 'client_initiated') {
            localStorage.removeItem(AUTH_TOKEN)
            window.location.href = redirectionUrl ? redirectionUrl :"https://www.daakia.co.in/"
        }

    }

    const getMeetingConfiguration = async () => {
        setLoading(true)
        const response = await CommonService.get(MEETING_CONFIG, {
            meeting_uid: params.meeting_id,
        });
        setRedirectionUrl(response?.data?.meeting_end_redirect_url)
        setLoading(false)
    }

/*    const transcribingStatusChanged = (transcrerStatus) => {
        console.log("transcribingStatusChanged: ", transcrerStatus);
        if (transcrerStatus === true) {
            console.log("transcription started!");
        }
        else {
            console.log("transcription finished!");
        }

    }*/

/*    const handleTranscriptionChunkReceived = (transcriptionChunk) => {
        setDaakiaTranscription(items => [ ...items, {
            participantName: transcriptionChunk?.data?.participant?.name,
            transcriptionChunk: transcriptionChunk?.data?.final,
            time: moment(new Date()).format("HH:mm")
        }])
    }*/

/*
    const handleDaakMeetApiReady = apiObj => {
        // console.log("apiObj: ", apiObj)

        apiRef.current = apiObj;
        //To find meeting div and add style for full screen
        let outerHtml = apiRef.current._parentNode.outerHTML
        const idMatch = outerHtml.match(/id="([^"]*)"/)[1];
        let meetingStyle = document.getElementById(idMatch)
        meetingStyle.style.height = '100vh'

        apiObj.addEventListener('participantRoleChanged', function (event) {
            // console.log("event: ", event)
            if(event.role === 'moderator') {
                // apiObj.executeCommand('revokeModerator', event.id);

                // apiObj.executeCommand('toggleLobby', event.id);
            }
        });
        // apiObj.addEventListener('participantJoined', (participant) => {
        //     const role = participant.role; // Access the role here
        //     console.log("role: ", role)
        // });

        let userLeftId = {};
        apiRef.current.on("participantJoined", (payload) => {

            if(payload?.displayName === "Transcriber") {
                setTranscriberId(payload?.id);
                setTranscriberVisible(true)
                transId = payload?.id;

            }
        });
        apiRef.current.on("participantLeft", (payload) => {
            if(payload?.id === transId){
                setTranscriberVisible(prevState => !prevState)
                transId = null;
            }

        });

        apiRef.current.on("transcribingStatusChanged", transcribingStatusChanged);
        apiRef.current.on("transcriptionChunkReceived", handleTranscriptionChunkReceived);
    };
*/


    return(
        <div >
            <Spin tip="Loading..." spinning={loading}>
                {/*<h3 style={{textAlign: "center", fontWeight: 600}}>Daakia Shared Meet</h3>*/}
                {/*<div style={{textAlign: 'center', margin: "20px"}}>*/}
                {/*    <img alt={'logo'} className={"daak-logo"}*/}
                {/*         src={'https://daakia-xmpp.s3.amazonaws.com/Logo-Transparent_1689057272998.png'}/>*/}
                {/*</div>*/}

                {(isMeetingExist === false && inLobby === false ? <>
                    <div style={{display: "block", marginTop: "10%"}}>
                    <h4 style={{textAlign: "center", fontWeight: 600, top: '50%'}}>
                        The meeting you are trying <br/> to join does not exist or expired. Please contact the host
                    </h4>
                    <p style={{textAlign: 'center'}}>You Will Be Redirected In {countdown} Seconds</p>
                    </div>
                </> : inLobby || hostJoined === false ? <Lobby meeting_time={meetingTime} />  :
                        <>
                            <DaakiaVC
                                id={encoder(params.meeting_id)}
                                licenseKey={meetingData?.saas_id}
                                websiteBaseUrl={window.location.origin}
                                apiToken={hostToken}
                                onClose={handelDaakiaVcCloseCallBack}
                            />
                        </>
                    /*<div className={"d-flex"}>
                        <DaakiaMeeting
                        domain={process.env.REACT_APP_CONFRENCE_HOST}
                        roomName={params.meeting_id}
                        // spinner = { renderSpinner }
                        configOverwrite={{
                            logging: {
                                defaultLogLevel: 'error',
                                disableLogCollector: true
                            },
                            hideLobbyButton: false,
                            autoKnockLobby: false,
                            subject: meetingData?.meeting_title,
          /!*                  toolbarButtons: [
                                'microphone',
                                'camera',
                                'fullscreen',
                                'fodeviceselection',
                                'hangup',
                                'chat',
                                'settings',
                                'tileview',
                                'desktop',
                                'raisehand',
                                'download',
                                'help',
                                'profile',
                                'stats',
                                'noisesuppression',
                                'select-background',
                                'participants-pane',
                                // 'transcription-pane'
                            ],*!/
                            transcription: {
                                enabled: false,
                                autoTranscribeOnRecord: false
                            }
                        }}
                        userInfo={{
                            displayName: 'Friendly name',
                            email: 'your email'
                        }}
                        getIFrameRef = { setupMeetingIFrameRef }
                        onReadyToClose = { () => onMeetingClose() }
                        onApiReady = { (externalApi) => {handleDaakMeetApiReady(externalApi)} }

                    >
                        <iframe
                            title="Daakia Meeting"
                            srcDoc={`<!DOCTYPE html><html><head><style>${iframeStyle}</style></head><body></body></html>`}

                        />
                    </DaakiaMeeting>
                        {transcriberVisible && (
                            <ConfrenceTranscription
                                daakiaTranscription={daakiaTranscription}
                                visible={transcriberVisible} />
                        )}

                </div>*/
                )}
            </Spin>


        </div>
    )
}


export default MeetingInvite;
